<script setup>
import Dropdown from '@/components/ui/Dropdown.vue'
import { ref, defineProps, defineEmits } from 'vue'
const emits = defineEmits(['search'])
const props = defineProps({
  search: Object,
  searchConditions: Array
})
const keyword = ref(null)
const selectOption = ref(null)

const searchAction = (e) => {
  const input = keyword.value
  if (props.search) props.search(input.value, selectOption.value?.searchValue)
  emits('search', input.value, selectOption.value?.searchValue)
}
</script>

<template>
  <div class="wrapper">
    <div class="search-bar-wrapper">
      <Dropdown
        v-if="searchConditions"
        class="select-wrapper"
        :options="searchConditions"
        :disabled="false"
        name="zipcode"
        :maxItem="3"
        ref="selectOption"
      />
      <label class="input-wrapper">
        <input type="text" id="" class="" placeholder="" ref="keyword" v-on:keyup.enter="searchAction" />
        <button type="button" id="" @click="searchAction">
          <span class="material-icons-outlined"> search </span>
        </button>
      </label>
    </div>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: var(--wf-text-16);
}
.wrapper {
  display: flex;
  justify-content: center;
}
.select-wrapper {
  margin: 0;
  text-align: left;
}

.search-bar-wrapper {
  display: flex;
  width: 50%;
}

.input-wrapper {
  display: flex;
  width: 100%;
}
.input-wrapper input {
  width: 100%;
  border: 2px solid var(--wf-primary-color);
  padding: 0.5em 1em;
  border-radius: 0px;
}
.input-wrapper input::placeholder {
  color: var(--wf-gray-color);
}

.input-wrapper button {
  background-color: var(--wf-primary-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 0.3em;
  width: 44px;
}
.input-wrapper button span {
  color: #fff;
  font-size: 28px !important;
}

:deep(.dropdown-input) {
  border-radius: 0px;
}
</style>
