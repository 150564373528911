<script>
import MainBackground from '@/assets/images/004.jpg'
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'
import PaginationBarVue from '@/components/ui/PaginationBar.vue'
import { getManyLab, getLab } from '@/api/labs'
import InfoCardVue from '@/components/ui/InfoCard.vue'
import SearchBarVue from '@/components/ui/SearchBar.vue'
import LoadingVue from '@/components/ui/Loading.vue'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: { InfoCardVue, SearchBarVue, PaginationBarVue, ImageBackgroundVue, LoadingVue },
  data() {
    return {
      labList: [],
      persistLabList: [],
      dataCount: 0,
      searchQuery: {},
      searchConditions: [],
      src: MainBackground,
      isLoading: false
    }
  },
  setup() {},
  created() {},
  async mounted() {
    this.searchConditions = [
      { value: 'all', text: '전체' },
      { value: 'labName', text: LanguageConverter({ en: 'Lab Name', kr: '연구실이름' }) },
      { value: 'departmentName', text: LanguageConverter({ en: 'Department', kr: '학과/부서' }) },
      { value: 'leadResearcherName', text: LanguageConverter({ en: 'Lead Researcher', kr: '대표연구자' }) },
      { value: 'keyword', text: LanguageConverter({ en: 'Keyword', kr: '키워드' }) }
      // { value: 'sixTechnologyLarge', text: '6T대분류' }
    ]

    this.loadData()
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    cardClickHandler(arg) {
      this.$router.push(arg.link)
    },

    searchAction(input, condition) {
      this.$router.push(`/researchField/result?q=${`${condition}=${input}`}`)
      const obj = {}
      obj[condition] = input
      this.searchQuery = obj
    },

    sortLabData(a, b) {
      if (a.leadResearcherName < b.leadResearcherName) {
        return -1 // a가 먼저
      }

      return 1 // b가 먼저
    },

    mapLabData(item) {
      return {
        src: item.leadResearcherImgUrl,
        data: {
          title: LanguageConverter({ en: item.labNameEn, kr: item.labName }),
          subtitle: LanguageConverter({ en: item.leadResearcherNameEn, kr: item.leadResearcherName }),
          infoList: [
            {
              head: LanguageConverter({ en: 'Position', kr: '직위' }),
              desc: LanguageConverter({ en: item.leadResearcherPosition, kr: item.leadResearcherPosition })
            },
            {
              head: LanguageConverter({ en: 'Institution', kr: '소속기관' }),
              desc: LanguageConverter({ en: item.affiliatedInstitutionName, kr: item.affiliatedInstitutionName })
            },
            {
              head: LanguageConverter({ en: 'MainField', kr: '분야' }),
              desc: LanguageConverter({ en: item.sixTechnologyMedium, kr: item.sixTechnologyMedium })
            },
            {
              head: LanguageConverter({ en: 'Keywords', kr: '키워드' }),
              desc: LanguageConverter({
                en: item.keywordEn
                  ?.split(',')
                  .slice(0, 3)
                  .reduce((acc, cur) => acc + ',' + cur),
                kr: item.keyword
                  ?.split(',')
                  .slice(0, 3)
                  .reduce((acc, cur) => acc + ',' + cur)
              })
            }
          ],
          link: `/lab/${item.labNo}`
        }
      }
    },

    async searchByQueryString(pageIdx) {
      const splited = this.$route.query.q?.split('=')
      const obj = {}

      for (let i = 0; i < splited.length; i += 2) {
        obj[splited[i]] = splited[i + 1]
      }
      this.searchQuery = obj

      // eslint-disable-next-line no-prototype-builtins
      if (this.searchQuery.hasOwnProperty('all')) {
        this.persistLabList = []
        for (const condition of this.searchConditions) {
          if (condition.value === 'all') continue

          const query = {}
          query[condition.value] = this.searchQuery.all
          const result = await getManyLab(query)

          if (result.items) this.persistLabList = [...this.persistLabList, ...result.items]
        }

        this.persistLabList = this.persistLabList.reduce((acc, cur) => {
          if (acc.findIndex((item) => item.labNo === cur.labNo) === -1) acc.push(cur)
          return acc
        }, [])
        this.dataCount = this.persistLabList.length

        const offset = (pageIdx - 1) * 12
        this.labList = this.persistLabList
          .slice(offset, offset + 12)
          .sort(this.sortLabData)
          .map(this.mapLabData)

        return
      }

      const result = await getManyLab({ limit: 12, offset: (pageIdx - 1) * 12, ...this.searchQuery })
      if (!(result.items instanceof Array)) {
        this.labList = []
        this.dataCount = 0
        return
      }

      this.labList = result.items.sort(this.sortLabData).map(this.mapLabData)
      this.dataCount = result.count
    },
    async searchByLabs(pageIdx) {
      const offset = (pageIdx - 1) * 12
      const tempLabs = []
      const labNoList = this.$route.query.labs?.split(',')

      for (let i = offset, lst = offset + 12; i < lst; i++) {
        const labNo = labNoList[i]
        if (!labNo) continue

        const lab = await getLab(labNo)
        if (lab.code === 'DB_DATA_NOT_FOUND') continue

        tempLabs.push(lab)
      }

      this.labList = tempLabs.sort(this.sortLabData).map(this.mapLabData)
      this.dataCount = labNoList.length
    },
    async loadData(pageIdx = 1) {
      this.isLoading = true
      if (this.$route.query.q) await this.searchByQueryString(pageIdx)
      else if (this.$route.query.labs) await this.searchByLabs(pageIdx)
      this.isLoading = false
    },

    paginationClickHandler({ itemIdx }) {
      this.loadData(itemIdx)
    }
  },
  watch: {
    async $route(to, from) {
      await this.loadData()
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading"></LoadingVue>
  <article class="wf__research-info">
    <ImageBackgroundVue :src="src">
      <div>
        <h1 class="wf__default-title">{{ LanguageConverter({ en: 'Research Field Search', kr: '연구분야 검색' }) }}</h1>
        <p class="wf__research-info-description">
          {{
            LanguageConverter({
              en: 'Researchers belonging to the contracting institution (transfer faculty, transfer researcher) can be inquired by researcher or department.',
              kr: '연구실, 기관별, 연구분야별로 검색이 가능합니다.'
            })
          }}
        </p>
      </div>
    </ImageBackgroundVue>
  </article>

  <section class="wf__research">
    <div class="wf__research-results">
      <SearchBarVue
        v-if="searchConditions.length !== 0"
        class="wf__research-search-bar"
        :searchConditions="searchConditions"
        @search="searchAction"
      />

      <div class="wf__research-results-head">
        총<span class="emphasized">{{ dataCount }}</span>
        명
      </div>

      <div class="wf__research-results-wrapper">
        <div class="wf__research-results-item wf__card" v-for="(lab, i) in labList" :key="i">
          <InfoCardVue :src="lab.src" :data="lab.data" @onClick="cardClickHandler" />
        </div>
      </div>

      <div class="wf__research-pagination">
        <PaginationBarVue v-if="dataCount !== 0" :totalItems="dataCount" :itemsPerPage="12" @onItemClick="paginationClickHandler" />
      </div>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__research {
  min-height: 600px;
}

.wf__research-info {
  margin-bottom: 2.5em;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.wf__research-search-bar {
  margin-top: 3em;
  margin-bottom: 4em;
}
.wf__research-info-description {
  font-size: var(--wf-text-16);
  margin-top: 0.75em;
  max-width: 36em;
  padding: 0 1em;
}

.wf__research-results {
  text-align: left;
  font-size: var(--wf-text-16);
}
.emphasized {
  color: var(--wf-primary-color);
  padding: 0 0.25em;
  font-weight: bold;
}
.wf__research-results-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 1em;
}

.wf__research-results-item {
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
}

.wf__research-pagination {
  margin: 3em 0;
}

@media screen and (max-width: 992px) {
  .wf__research-results-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .wf__research-results-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
